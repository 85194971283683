import React, { useEffect, useRef } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

export const Top_MenuComp = ({ handleTabClick, activeTab, tabs, loadingData = false }) => {
    const tabsRef = useRef(null);
    const itemRef = useRef();
    useEffect(() => {
        // Prevent click event when dragging horizontally
        const tabsElement = tabsRef.current;
        if (tabsElement) {
            let isDown = false;
            let startX;
            let scrollLeft;
            let handleMouseMove; // Lưu trữ hàm xử lý sự kiện

            const onMouseDown = (e) => {
                isDown = true;
                startX = e.pageX - tabsElement.offsetLeft;
                scrollLeft = tabsElement.scrollLeft;
            };
            const onMouseLeave = () => {
                isDown = false;
                startX = 0;
                scrollLeft = 0;
            };
            const onMouseUp = () => {
                isDown = false;
                startX = 0;
                scrollLeft = 0;
            };
            handleMouseMove = (e) => {
                // Gán hàm xử lý sự kiện vào biến handleMouseMove
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - tabsElement.offsetLeft;
                const walk = (x - startX) * 2; // Adjust scroll speed if necessary
                tabsElement.scrollLeft = scrollLeft - walk;
            };

            tabsElement.addEventListener('wheel', (e) => {
                e.preventDefault();
                // e.stopPropagation()
                tabsElement.scrollLeft = tabsElement.scrollLeft + e.deltaY * 2;
            });
            tabsElement.addEventListener('mousedown', onMouseDown);
            tabsElement.addEventListener('mouseleave', onMouseLeave);
            tabsElement.addEventListener('mouseup', onMouseUp);
            tabsElement.addEventListener('mousemove', handleMouseMove); // Sử dụng biến handleMouseMove

            return () => {
                // Cleanup event listeners if component unmounts

                tabsElement.removeEventListener('mousedown', onMouseDown); // Sử dụng hàm xử lý đã được lưu trữ
                tabsElement.removeEventListener('mouseleave', onMouseLeave);
                tabsElement.removeEventListener('mouseup', onMouseUp);
                tabsElement.removeEventListener('mousemove', handleMouseMove); // Sử dụng biến handleMouseMove
            };
        }
    }, [activeTab]);

    return (
        <div className="top-menu">
            <ul id="menu-ul" ref={tabsRef} style={{ position: 'relative', scrollBehavior: 'smooth' }}>
                {tabs.map((tab) => (
                    <li
                        key={tab.value}
                        id={tab.value === activeTab ? 'active' : null}
                        className={tab.value === activeTab ? 'active ' : ''}
                        style={{
                            position: tab.value === 0 ? 'sticky' : '',
                            left: '0',
                            zIndex: tab.value === 0 ? '3' : '1',
                            right: '0',
                            backgroundColor: 'white',
                            boxShadow: tab.value === 0 && 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        }}
                    >
                        <button
                            ref={itemRef}
                            onClick={() => {
                                handleTabClick(tab.value);
                            }}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}
                        >
                            <i className="ic-square " style={{ backgroundColor: tab.color }}></i>
                            {tab.label} <span className="red ">({tab.count})</span>
                            {loadingData && tab.value === activeTab && (
                                <ClipLoader
                                    color="#029047"
                                    size={20}
                                    cssOverride={{ animation: 'clipload 1s linear infinite' }}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            )}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export const Top_Menu = React.memo(Top_MenuComp);
